<router>
{
    name: 'Versions',
}
</router>
<template lang="pug">
v-container
    v-card(light, flat)
        EmailEditor

</template>
<script>

import EmailEditor from '@/components/devops/emaileditor';

export default {
    meta: {
        role: 'devopscomms'
    },
    components: {
        EmailEditor
    }
};
</script>

<style scoped>
</style>
